import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>
        <p className="text-xl mt-20">
          Hey, I'm Naseem!👋 I'm a software developer and student in school, who
          builds engaging websites like this one and makes YouTube videos about
          computer science & software engineering. I'm currently working towards
          my Bachelor's degree in Computer Science at Western Governors
          University, I aspire to end up working in networking and security, but
          I'm also interested in web development, and software development, I
          hope to learn more the fields in the future, and I'm always open to
          new opportunities.
        </p>

        <br />
        <p className="text-xl">
          I am always open to learning more and gaining new knowledge, if you'd
          like to send me some videos or articles you can email me or contact me
          on discord or my other socials. I'm currently working in the emergency
          services field, and I'm also a firefighter. I would like to change
          careers to the tech field, and I'm currently working towards that
          goal. I currently work on small coding projects, like this terminal
          website that you are on right now, and small bots that you can use on
          discord, I also use my free time to learn more about coding.
        </p>
      </div>
    </div>
  );
};

export default About;
