import React from "react";
import githubapi from "../assets/portfolio/github-api.jpg";
import livecodeeditor from "../assets/portfolio/live-code-editor-v2.jpg";
import parallaxbackground from "../assets/portfolio/parallax-background.jpg";
import parallexslider from "../assets/portfolio/parallex-slider.jpg";
import reactPortfolio from "../assets/portfolio/react-one-portfolio.jpg";
import nftpage from "../assets/portfolio/responsive-nft-landing-page.jpg";
import snakegame from "../assets/portfolio/snake-game.jpg";
import TerminalWebsite from "../assets/portfolio/terminal-website.jpg";
import weatherapp from "../assets/portfolio/weather-app.jpg";
import wheelspin from "../assets/portfolio/wheel-spin-app.jpg";

const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: reactPortfolio,
      href: "https://nhaidar.dev/",
      href2: "https://github.com/oexwashere/one-portfolio",
    },
    {
      id: 2,
      src: TerminalWebsite,
      href: "https://oexwashere.github.io/oxcodes/",
      href2: "https://github.com/oexwashere/oxcodes",
    },
    {
      id: 3,
      src: nftpage,
      href: "https://responsive-nft-landing-page.nhaidar.dev/",
      href2: "https://github.com/oexwashere/responsive-nft-landing-page",
    },
    {
      id: 4,
      src: githubapi,
      href: "https://github-api.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Github-API",
    },
    {
      id: 5,
      src: snakegame,
      href: "https://snake-game.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Snake-Game",
    },
    {
      id: 6,
      src: weatherapp,
      href: "https://weather-app.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Weather-App",
    },
    {
      id: 7,
      src: wheelspin,
      href: "https://wheel-spin-app.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Spin-Wheel-App",
    },
    {
      id: 8,
      src: parallexslider,
      href: "https://parallex-slider-demo.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Parallex-Slider",
    },
    {
      id: 9,
      src: livecodeeditor,
      href: "https://live-code-editor.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Live-Code-Editor-V2",
    },
    {
      id: 10,
      src: parallaxbackground,
      href: "https://parallax-background.nhaidar.dev/",
      href2: "https://github.com/oexwashere/Parallax-Background",
    },
  ];

  return (
    <div
      name="portfolio"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-6">Check out some of my work right here</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {portfolios.map(({ id, src, href, href2 }) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105"
              />
              <div className="flex items-center justify-center">
                <button
                  onClick={() => window.open(href, "_blank")}
                  className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                >
                  Demo
                </button>
                <button
                  onClick={() => window.open(href2, "_blank")}
                  className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                >
                  Code
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
